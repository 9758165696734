:root{
  --tech-used-to-socials-area: 50px;
  --main-page-top-margin: 120px;
}
body{
  background-color: #fff;
  margin: 0;
  font-family: 'League Spartan', sans-serif;
}

.App{
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.decoration-vector{
  content: url(./assets/Verctor_1.svg);
  position: fixed;
  width: 58em;
  top: -250px;
  left: -200px;
  z-index: -1;
}

@media(max-width: 500px){
  .decoration-vector {
    top: -100px;
    width: 40em;
  }
}

.decoration-vector:nth-child(2){
  position: fixed;
  top: initial;
  width: 70%;
  bottom: -220px;
  left: unset;
  right: -500px;
  transform: rotate(280deg);
}

@media(min-width: 1441px) {
  .decoration-vector:nth-child(2) {
    right: -600px;
    bottom: -400px;
  }
}

@media (max-width: 900px) {
  .decoration-vector:nth-child(2) {
      display: none;
    }
}
.loading__container {
    display: flex;
    width: 100%;
    min-height: 100vh;
    justify-content: center;
    height: 500px;
}
.loading__container svg{
    position: fixed;
    top: 50%;
}
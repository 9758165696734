.cookieBanner{
    position: fixed;
    bottom: 30px;
    width: 60vw;
    background-color: #f7f2d5;
    padding: 5px 25px;
    transform: translateY(150%);
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 999999;
    animation: showUp 1s 1s forwards ease-in-out;
    transition: 1000ms;
}

.cookieBanner .cookieBanner__text{
    padding: 5px 15px;
    line-height: 1.05;
}

@keyframes showUp {
    0%{
        transform: translateY(150%);
    } 
    100%{
        transform: translateY(0%);
    }
}
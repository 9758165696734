form.login{
    margin: 100px;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 5px 5px 10px 1px #eee;
}

@media (max-width: 600px) {
    form.login{
        padding: 30px 20px;
        margin: 80px 20px;
    }
}
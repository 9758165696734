div.logout{
    margin: 100px;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 5px 5px 10px 1px #eee;
}
div.logout > * {
    padding: 20px;
    text-decoration: none;
}

@media (max-width: 458px) {
    div.logout{
        margin: 40px 20px;
    }
    div.logout > * {
        padding: 25px 10px;
    }
}
.projectWrapper {
    margin-left: 240px; 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: clamp(10px, 2vw, 30px);
}

.pagination {
    margin: 0 auto;
    flex-basis: 100%;
}
.paginationHolder{
    min-width: 40%;
    margin: 100px auto 0;
}
.MuiPagination-ul{
    justify-content: center;
}

@media (min-width: 1700px) {
    .projectWrapper {
        max-width: 1500px;
        gap: clamp(15px, 3vw, 35px);
    }
}

@media (max-width: 900px) {
    .projectWrapper {
        margin-left: 0;
    }
}
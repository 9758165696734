.logo{
    border-radius: 50%;
    text-align: center;
    margin-right: 50px;
}
.logo > img {
    width: 65px;
    height: 65px;
}

@media (max-width: 900px){
    .logo{
        margin-right: 0
    }
}
.how-it-works__container {
  display: flex !important;
  min-height: calc(100vh - 90px);
  max-width: 1300px !important;
  min-height: 100vh;
  gap: 3%;
  padding-top: 50px;
}
.how-it-works__buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 150px auto 0;
  gap: 3px;
  width: 22%;
  height: 300px;
  border-bottom: 2px solid rgb(224, 224, 224);
  border-top: 2px solid rgb(224, 224, 224);
}
.how-it-works__button {
  font-size: 20px !important;
  color: black !important;
}
.active {
  font-size: 20px !important;
  color: #dbc111 !important;
}
.how-it-works__content {
  width: 75%;
  min-height: 100vh;
}
.how-it-works {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin: auto;
}
.how-it-works > p {
  font-size: 1.3rem;
  line-height: 1.5;
}
.titlebar {
  width: 100%;
  text-align: center;
}
.titlebar > h1 {
  display: inline-block;
  position: relative;
  font-size: 2.5rem;
}
.titlebar > h1::before {
  content: "";
  background: #dbc110;
  width: 3px;
  height: 40px;
  display: block;
  z-index: -1;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
}

.grid__container {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
  max-height: 270px;
  min-width: 270px;
  padding: 1rem;
  background-color: rgb(255, 255, 255);
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 2rem 0 !important;
  text-align: left;
  line-height: normal;
  transition: all ease 0.5s;
}
.grid__container:hover {
  transition: all ease 0.5s;
  transform: scale(1.1);
}
.grid__img {
  width: 50px;
  height: 50px;
}
.grid__text {
  font-size: 1.1rem;
}

.content {
  display: flex;
}

.content__text,
.content__logo {
  width: 50%;
}
.content__text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}
.logo__link {
  width: 150px;
  height: 150px;
  transition: all 0.5s ease;
}

.keywords {
  display: flex;
  justify-content: space-between;
}
.keyword {
  font-size: 0.7rem;
  font-weight: 600;
  padding: 5px;
  background-color: #dbc110;
  box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.technology-img {
  transition: all ease 0.3s;
}
.technology-img:hover {
  transition: all ease 0.3s;
  transform: scale(1.1);
}

.demo-project {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 14px;
  flex-wrap: wrap;
  line-height: 1.5;
}

.info__container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 45%;
  text-align: left;
  padding: 15px;
}

.info__container > h1,
h2,
h3 {
  margin: 0;
}

h3:hover{
  text-decoration: underline;
}

.img__container {
  width: 50%;
  font-size: 0;
  line-height: 0;
}

.img-demo {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.joinUs {
  margin-top: 15%;
}

.demo-project__desc {
  margin-top: 30px;
  font-size: 16px;
}

@media screen and (max-width: 1023px) {
  .how-it-works__container {
    flex-direction: column;
  }
  .how-it-works__buttons {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 0;
    height: auto;
  }
  .how-it-works__content {
    width: 100%;
    height: auto;
  }
  .joinUs {
    margin: 0;
  }
  .demo-project {
    flex-direction: column;
    flex-direction: column-reverse;
    width: 100%;
    max-width: 500px;
  }
  .info__container {
    margin: 0 auto;
    width: 90%;
  }
  .img__container {
    width: 100%;
    height: 300px
  }
  .img-demo {
    border-radius: 10px 10px 0 0;
  }

    .demo-project__desc{
      display: none;
    }
}

@media screen and (max-width: 750px) {
  .how-it-works__buttons {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .content {
    flex-direction: column;
  }
  .content__text,
  .content__logo {
    width: 100%;
  }
}
.footer__container {
  box-sizing: border-box;
  width: 100%;
  min-height: 550px;
  margin-top: 2.5rem;
  padding: 3rem 2rem;
  box-sizing: border-box;
  background-color: rgba(43, 43, 43, 255);
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 0.8;
  z-index: 10;
}

.info__container {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
}

.logo__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logo-img {
  width: 100%;
  max-width: 280px;
  height: auto;
  max-height: 70px;
  margin-right: 0.5rem;
}

.logo-title {
  font-size: 1.9rem;
  font-weight: 800;
  line-height: 0.3;
  color: #fff;
}

.motto__container {
  font-size: 1.7rem;
  font-weight: 600;
  color: #0366d4;
  line-height: 1;
}

.copyrights__container {
  font-size: 0.9rem;
  color: rgba(113, 113, 113, 255);
}

.--from-900 {
  display: block;
}

.--to-900 {
  display: none;
}

@media (max-width: 899px) {
  .--from-900 {
    display: none;
  }
  .--to-900 {
    display: block;
  }
}

.technology__container{
  margin-bottom: 30px;
}

.section_title {
  color: #7d7d7d;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 0;
}

.links_container {
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: space-between;
  margin-bottom: 30px;
}

.link {
    text-decoration: none !important;
}

@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;600&display=swap');

.comments {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 2vw;
  padding-top: 20px;
}
section.existing-comments > * {
  margin: 15px 20px;
}
.comment-author-details {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  padding: 15px;
  color: #DBC112
}

.comment-text {
  padding: 10px 15px;
  border-radius: 5px;
}

.loggedInAuthorOfComment{
  text-align: right;
}

.loginLink {
  color: #DBC112;
  transition: 300ms;
}

.loginLink:hover{
  color: black;
}
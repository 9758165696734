.scrollButton {
  position: fixed;
  bottom: -50px;
  right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #DBC111;
  z-index: 9999;
  cursor: pointer;
  opacity: 50%;
  transition: 300ms;
  overflow: hidden;
}

.scrollButton.visible {
  bottom: 50px;
}

.scrollButton:hover{
  opacity: 100%;
}

.scrollButton svg{
  font-size: 35px;
  color: white;
}

.scrollButton:hover > svg{
  animation: scrollMove 1000ms infinite;
}

@keyframes scrollMove {
  0%{
    display: block;
    transform: translateY(0);
  }
  49%{
    display: block;
    transform: translateY(-30px);
  }
  50%{
    display: none;
    transform: translateY(30px);
  }
  51%{
    display: block;
    transform: translateY(30px);
  }
  100%{
    display: block;
    transform: translateY(0);
  }
}

@media (max-width: 700px) {
  .scrollButton {
    height: 40px;
    width: 40px;
    right: 25px;
  }
  .scrollButton svg{
    font-size: 25px;
  }
  .scrollButton.visible {
    bottom: 25px;
  }
}
.project-details {
  border-bottom: 0.1px solid #fff;
  display: flex;
  flex-direction: column;
  width: 80%;
  min-height: calc(100vh - 90px);
  margin: 0 auto;
  padding: 1rem 0;
}
.details__container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: clamp(10px, 3vw, 80px);
}
.details-wrapper {
  display: flex;
  max-width: 500px;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.details-wrapper > * {
  padding: 10px 5px;
  font-family: 'League Spartan', sans-serif !important;
}
.details-wrapper > .project__code-link,
.details-wrapper > .project__live-link {
  font-size: 15px;
  padding: 0 5px;
  display: flex;
  gap: 5px;
}
.badges-wrapper {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}
.tech-used {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px;
  font-family: 'League Spartan', sans-serif !important;
}
.image-wrapper {
  height: 400px;
  max-width: 650px;
  text-align: center;
  border-radius: 10px;
}
.image-wrapper > img {
  height: clamp(25%, 50vw, 80%);
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgb(150, 150, 150);
  object-fit: scale-down;
}
.description {
  font-size: 1.25rem;
  padding: 30px 5px;
  text-align: left;
}
.description > h1 {
  margin-bottom: 0;
}
@media screen and (max-width: 1350px) {
  .details__container {
    margin-top: 20px;
  }
  .project-details {
    width: 90%;
  }
  .image-wrapper {
    height: 300px;
  }
  .tech-used {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 900px) {
  .project-details {
    align-items: center;
  }
  .details__container {
    flex-direction: column-reverse;
    align-items: center;
  }
  .project__title {
    text-align: center;
    font-size: 2.5rem !important;
  }
  .badges-wrapper {
    margin: 0;
  }
  .image-wrapper {
    width: 90%;
    margin: 0 auto;
    display: flex;
  }
  .image-wrapper > img {
    height: clamp(25%, 40vw, 90%);
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 767px) {
  .project-details {
    width: 95%;
  }
  .details__container {
    justify-content: flex-end;
  }
  .image-wrapper {
    margin: 0 auto;
  }
}

@media screen and (max-width: 550px) {
  .image-wrapper {
    margin: 0 auto;
    max-height: 300px;
  }
}

@media (min-width: 1000px) {
  .description {
    width: 80%;
    margin: 0 auto;
  }
}

@media (min-width: 1900px) {
  .description {
    width: 70%;
    margin: 0 auto;
  }
}
.filter-menu {
    width: 240px;
    position: fixed;
    overflow-Y: scroll;
    overflow-X: hidden;
    transition: all 150ms ease-out;
    z-index: 5;
}

.sort-switcher {
    display: none;
}

.filter-menu::-webkit-scrollbar {
    width: 2px;
    background-color: #f2eed0;
}

.filter-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.filter-menu::-webkit-scrollbar-thumb {
    background: #DBC111;
    border-radius: 10px;
}

.filter-menu::-webkit-scrollbar-thumb:hover {
    background: #afa14a;
}

@media(max-width: 900px) {
    .filter-menu {
        width: 300px;
        background-color: #F9F9F9;
        box-shadow: 10px 10px 10px rgba(255,255,255,0.1);
        top: 88px;
        height: 85%;
        left: 0px;
        padding-left: 10px;
        padding-bottom: 20px;
    }
    .filter-menu > * {
        font-size: 12px !important;
    }
}
.card__container {
    width: 300px;
    min-height: 450px;
}

.card__content {
    width: 100%;
    height: 100%;
}

.card__content:hover {
    border: 2px solid #DBC111;
    transform: scale(1.02);
}



